<script>
import ApiService from "../../Utils/axios.js";
// import axios from "axios";
export default {
  data() {
    return {
      checklists: [],
      done: false,
      cols: [
        {
          label: "Id",
          key: "id",
          sortable: true,
        },
        {
          label: "Titre",
          key: "title",
          sortable: true,
        },
        {
          label: "Description",
          key: "description",
        },
        {
          lable: "Voir",
          key: "show",
        },
      ],
    };
  },

  beforeMount() {
    this.fetchList();
  },

  methods: {
    fetchList() {
      this.done = false;
      // axios
      new ApiService()
        .get("/api/checklist")
        .then((res) => {
          this.checklists = res.data;
          this.done = true;
          console.log("checklists", this.checklists);
          console.log("res", res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<template>
  <div>
    <b-container class="mt-5">
      <b-row>
        <b-col-3 class="mb-3">
          <b-button variant="success">Ajouter</b-button>
        </b-col-3>
      </b-row>
      <b-row>
        <b-col>
          <b-table striped hover :items="checklists" :fields="cols">
            <template v-slot:cell(show)="data"
              ><router-link :to="'/checklist/' + data.item.id"
                ><i class="fas fa-eye"></i></router-link
            ></template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
