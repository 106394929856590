<script>
import ApiService from "../../Utils/axios";
export default {
  data() {
    return {
      users: [],
      done: false,
      cols: [
        {
          label: "Id",
          key: "id",
          sortable: true,
        },
        {
          label: "Nom",
          key: "name",
          sortable: true,
        },
        {
          label: "E-Mail",
          key: "email",
        },
        {
          lable: "Modifier",
          key: "edit",
        },
        {
          lable: "Supprimer",
          key: "delete",
        },
      ],
    };
  },

  beforeMount() {
    this.fetchList();
  },

  methods: {
    onRemove(item) {
      this.item = item;
      this.modalMsg = item.name;
      if (confirm("Etes-vous sur de vouloir supprimer l'utilisateur?")) {
        this.remove(this.item);
      }
    },
    remove(item) {
      //   console.log(item.id);
      this.item = item;
      let api = new ApiService();
      api
        .delete("/api/user/" + this.item.id)
        .then((res) => {
          this.fetchList();
          this.$store.dispatch("Alert/activate", {
            text: "Patient supprimé avec succée",
            type: "success",
          });
        })
        .catch((err) => {
          if (err.response.data.code && err.response.data.code === "23000") {
            this.$store.dispatch("Alert/activate", {
              text: "Le patient ne peut pas être supprimé parce qu'il est utilisé par d'autres entités du système",
              type: "danger",
            });
          } else {
            this.$store.dispatch("Alert/activate", {
              text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
              type: "danger",
            });
          }
        });
    },
    fetchList() {
      this.done = false;
      new ApiService()
        .get("/api/user")
        .then((res) => {
          this.users = res.data;
          this.done = true;
          console.log("patient", this.users);
          console.log("res", res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<template>
  <div>
    <b-container class="mt-5">
      <b-row>
        <b-col-3 class="mb-3">
          <router-link to="/user/new" class="btn btn-success"
            >Ajouter</router-link
          >
        </b-col-3>
      </b-row>
      <b-row>
        <b-col>
          <b-table striped hover :items="users" :fields="cols">
            <template v-slot:cell(edit)="data"
              ><router-link :to="'/user/edit/' + data.item.id"
                ><i class="fas fa-edit"></i></router-link
            ></template>
            <template v-slot:cell(delete)="data"
              ><b-button
                @click="onRemove(data.item)"
                variant="iq-bg-danger"
                size="sm"
                ><i class="trash fas fa-trash"></i
              ></b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
