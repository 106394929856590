<template>
  <b-container class="mt-5">
    <div class="card">
      <div class="card-header d-flex">
        <div class="header-title">
          <h5 class="card-title">Créer un utilisateur</h5>
        </div>
      </div>
      <div class="card-body d-flex flex-column">
        <form @submit.prevent="submit">
          <div class="row">
            <div class="form-group col-12 col-lg-6">
              <label>Email</label>
              <input
                type="text"
                placeholder="Email"
                class="form-control mb-2"
                name="email"
              />
            </div>

            <div class="form-group col-12 col-lg-6">
              <label>Nom</label>
              <input
                type="text"
                placeholder="Nom"
                class="form-control mb-2"
                name="name"
              />
            </div>

            <div class="form-group col-12 col-lg-6">
              <label>Mot de passe</label>

              <b-input-group>
                <b-input-group-prepend is-text>
                  <b-form-checkbox v-model="show" switch class="mr-n2">
                  </b-form-checkbox>
                </b-input-group-prepend>
                <b-form-input
                  :type="show ? 'text' : 'password'"
                  placeholder="Mot de passe"
                  name="password"
                ></b-form-input>
              </b-input-group>
            </div>
          </div>

          <button type="submit" class="btn btn-primary mt-5">Valider</button>
        </form>
      </div>
    </div></b-container
  >
</template>

<script>
import ApiService from "../../Utils/axios";

export default {
  data: () => ({
    show: false,
  }),
  methods: {
    async submit(e) {
      const formData = new FormData(e.target);

      await new ApiService().post("/api/user", formData);

      this.$router.push("/user");
    },
  },
};
</script>

<style></style>
