<template>
  <b-container class="mt-5">
    <div class="card">
      <div class="card-header d-flex">
        <div class="header-title">
          <h5 class="card-title">Modifier un trajet</h5>
        </div>
      </div>
      <div v-if="journey" class="card-body d-flex flex-column">
        <form @submit.prevent="submit">
          <div class="row">
            <div class="form-group col-12 col-lg-6">
              <label>Reference</label>
              <input
                v-model="journey.reference"
                type="text"
                placeholder="Reference"
                class="form-control mb-2"
                name="reference"
              />
            </div>

            <div class="form-group col-12 col-lg-6">
              <label>Opération</label>
              <select
                v-model="journey.surgery.id"
                class="form-select mb-2"
                name="surgery_id"
              >
                <option value=""></option>
                <option v-for="s of surgery" :key="s.id" :value="s.id">
                  {{ s.code + " - " + s.denomination }}
                </option>
              </select>
            </div>

            <div class="form-group col-12 col-lg-6">
              <label>Patient</label>
              <select
                v-model="journey.patient.id"
                class="form-select mb-2"
                name="patient_id"
              >
                <option value=""></option>
                <option v-for="p of patient" :key="p.id" :value="p.id">
                  {{ p.patient_code + " - " + p.lastname + " " + p.firstname }}
                </option>
              </select>
            </div>

            <div class="form-group col-12 col-lg-6">
              <label>Date planifiée</label>
              <input
                type="date"
                placeholder="Date planifiée"
                class="form-control mb-2"
                name="scheduledDateTime"
                v-model="journey.scheduledDateTime"
              />
            </div>

            <div class="form-group col-12 col-lg-12">
              <label>Raison</label>
              <textarea
                v-model="journey.reason"
                placeholder="Raison"
                class="form-control mb-2"
                name="reason"
              />
            </div>
          </div>
          <div>
            <b-button type="submit" class="btn btn-success m-2">
              Modifier
            </b-button>

            <b-link class="btn btn-warning" to="/journey">Annuler</b-link>
          </div>
        </form>
      </div>
    </div></b-container
  >
</template>

<script>
import ApiService from "../../Utils/axios";

export default {
  data: () => ({
    journey: undefined,
    surgery: [],
    patient: [],
  }),
  mounted() {
    this.getJourney();
    this.getSurgery();
    this.getPatient();
  },
  methods: {
    async submit(e) {
      const formData = new FormData(e.target);

      await new ApiService().put(
        "/api/journey/" + this.$route.params.id,
        formData
      );

      this.$router.push("/journey");
    },
    async getSurgery() {
      const { data } = await new ApiService().get("/api/surgery");
      this.surgery = data;
    },
    async getPatient() {
      const { data } = await new ApiService().get("/api/patient");
      this.patient = data;
    },
    async getJourney() {
      const { data } = await new ApiService().get(
        "/api/journey/" + this.$route.params.id
      );
      this.journey = data.data;
    },
  },
};
</script>

<style></style>
