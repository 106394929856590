<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <router-link to="/" class="nav-link">Dynalists</router-link>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <!-- <li class="nav-item active">
          <router-link to="/post" class="nav-link">Articles</router-link>
        </li> -->
        <li class="nav-item">
          <router-link to="/checklist" class="nav-link"
            >Check-listes</router-link
          >
        </li>
        <li class="nav-item">
          <router-link to="/surgery" class="nav-link">Operations</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/journey" class="nav-link">Trajets</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/patient" class="nav-link">Patients</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/user" class="nav-link">Utilisateurs</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/evaluation" class="nav-link"
            >Evaluations</router-link
          >
        </li>
      </ul>
    </div>
    <button @click="logout" class="btn btn-outline-danger float-right mx-3">
      Deconnexion
    </button>
  </nav>
  <div class="container mt-5">
    <router-view />
  </div>
</template>

<script>
export default {
  methods: {
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/login");
    },
  },
};
</script>

<style></style>
