<script>
import ApiService from "../../Utils/axios.js";

export default {
  data() {
    return {
      patients: [],
      cols: [
        {
          label: "Id",
          key: "id",
          sortable: true,
        },
        {
          label: "Code",
          key: "patient_code",
          sortable: true,
        },
        {
          label: "Prénom",
          key: "firstname",
        },
        {
          label: "Nom",
          key: "lastname",
        },
        {
          label: "Date de naissance",
          key: "dateofbirth",
          sortable: true,
        },
        {
          label: "Genre",
          key: "gender",
          sortable: true,
        },
        {
          label: "Diabétique",
          key: "diabetic",
          sortable: true,
        },
        {
          label: "Intubation difficile?",
          key: "difficult_intubation",
          sortable: true,
        },
        {
          label: "Majeur?",
          key: "type",
          sortable: true,
        },
        {
          lable: "Modifier",
          key: "edit",
        },
        {
          lable: "Supprimer",
          key: "delete",
        },
      ],
      item: null,
      modalMsg: "",
    };
  },

  beforeMount() {
    this.fetchList();
  },

  methods: {
    onRemove(item) {
      this.item = item;
      this.modalMsg = item.name;
      if (confirm("Etes-vous sur de vouloir supprimer le patient?")) {
        this.remove(this.item);
      }
    },
    remove(item) {
      //   console.log(item.id);
      this.item = item;
      let api = new ApiService();
      api
        .delete("/api/patient/" + this.item.id)
        .then((res) => {
          this.fetchList();
          this.$store.dispatch("Alert/activate", {
            text: "Patient supprimé avec succée",
            type: "success",
          });
        })
        .catch((err) => {
          if (err.response.data.code && err.response.data.code === "23000") {
            this.$store.dispatch("Alert/activate", {
              text: "Le patient ne peut pas être supprimé parce qu'il est utilisé par d'autres entités du système",
              type: "danger",
            });
          } else {
            this.$store.dispatch("Alert/activate", {
              text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
              type: "danger",
            });
          }
        });
    },
    fetchList() {
      new ApiService()
        .get("/api/patient")
        .then((res) => {
          this.patients = res.data;
          console.log("res", res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<template>
  <div>
    <b-container class="mt-5">
      <b-row>
        <b-col-3 class="mb-3">
          <router-link to="/patient/new" class="btn btn-success"
            >Ajouter</router-link
          >
        </b-col-3>
      </b-row>
      <b-row>
        <b-col>
          <b-table striped hover :items="patients" :fields="cols">
            <template v-slot:cell(edit)="data"
              ><router-link :to="'/patient/edit/' + data.item.id"
                ><i class="fas fa-edit"></i
              ></router-link>
            </template>
            <template v-slot:cell(delete)="data"
              ><b-button
                @click="onRemove(data.item)"
                variant="iq-bg-danger"
                size="sm"
                ><i class="trash fas fa-trash"></i
              ></b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <!-- <b-modal id="modal-1" title="Attention!" @ok="remove(data.item)">
        <p class="my-4 modal-backdrop">
          Etes vous sure de vouloir supprimer le patient
           <span class="bold text-danger">{{ modalMsg }}</span> ? 
        </p>
      </b-modal> -->
    </b-container>
  </div>
</template>
<style>
.trash {
  color: darkred;
}
</style>
