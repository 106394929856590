import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";


// import { ModalPlugin } from 'bootstrap-vue';
import { BootstrapVue3 } from "bootstrap-vue-3";


// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

createApp(App).use(store).use(router).use(BootstrapVue3).mount("#app");
// createApp.use(ModalPlugin);