const state = {
  currentUser: null,
};

const mutations = {
  SET_CURRENT_USER(state, user) {
    state.currentUser = user;
  },
};

const getters = {
  // Whether the user is currently logged in.
  isLoggedIn(state) {
    return !!state.currentUser;
  },

  getCurrentUser(state) {
    return state.currentUser;
  },
};

const actions = {
  setUser({ commit }, payload) {
    commit("SET_CURRENT_USER", payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
