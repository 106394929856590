<template>
  <b-container class="mt-5">
    <div class="card">
      <div class="card-header d-flex">
        <div class="header-title">
          <h5 class="card-title">Editer un patient</h5>
        </div>
      </div>
      <div v-if="data" class="card-body d-flex flex-column">
        <div class="card-body d-flex flex-column">
          <form @submit.prevent="submit">
            <!-- <input type="hidden" name="_method" value="put" /> -->
            <div class="row">
              <div class="form-group col-12 col-lg-6">
                <label>Prénom</label>
                <input
                  type="text"
                  placeholder="Prénom"
                  class="form-control mb-2"
                  name="firstname"
                  v-model="data.firstname"
                  required
                />
              </div>

              <div class="form-group col-12 col-lg-6">
                <label>Nom</label>
                <input
                  type="text"
                  placeholder="Nom"
                  class="form-control mb-2"
                  name="lastname"
                  v-model="data.lastname"
                  required
                />
              </div>

              <div class="form-group col-12 col-lg-6">
                <label>Code</label>
                <input
                  type="text"
                  placeholder="Code"
                  class="form-control mb-2"
                  name="patient_code"
                  v-model="data.patient_code"
                  required
                />
              </div>

              <div class="form-group col-12 col-lg-6">
                <label>Date de naissance</label>
                <input
                  type="date"
                  placeholder="Date de naissance"
                  class="form-control mb-2"
                  name="dateofbirth"
                  v-model="data.dateofbirth"
                  required
                />
              </div>

              <div class="form-group col-12 col-lg-6">
                <label>Genre</label>
                <select
                  class="form-select mb-2"
                  name="gender"
                  v-model="data.gender"
                  required
                >
                  <option value=""></option>
                  <option value="M">Masculin</option>
                  <option value="F">Feminin</option>
                </select>
              </div>

              <div class="form-group col-12 col-lg-6">
                <label>Diabetique</label>
                <select
                  class="form-select mb-2"
                  name="diabetic"
                  v-model="data.diabetic"
                  required
                >
                  <option value=""></option>
                  <option value="1">Oui</option>
                  <option value="0">Non</option>
                </select>
              </div>

              <div class="form-group col-12 col-lg-6">
                <label>difficult_intubation</label>
                <select
                  class="form-select mb-2"
                  name="difficult_intubation"
                  v-model="data.difficult_intubation"
                  required
                >
                  <option value=""></option>
                  <option value="1">Oui</option>
                  <option value="0">Non</option>
                </select>
              </div>

              <div class="form-group col-12 col-lg-6">
                <label>Mineur</label>
                <select
                  class="form-select mb-2"
                  name="type"
                  v-model="data.type"
                  required
                >
                  <option value=""></option>
                  <option value="1">Oui</option>
                  <option value="0">Non</option>
                </select>
              </div>

              <!-- <div class="form-group col-12 col-lg-6">
              <label>Photo</label>
              <input
                type="file"
                accept="image/png, image/jpeg"
                placeholder="Date de naissance"
                class="form-control mb-2"
                name="photo"
              />
            </div> -->

              <!-- <div class="form-group col-12 col-lg-6">
              <label>consent_pdf</label>
              <input
                type="file"
                placeholder="Date de naissance"
                class="form-control mb-2"
                name="consent_pdf"
              />
            </div> -->
            </div>

            <div>
              <b-button type="submit" class="btn btn-success m-2">
                Modifier
              </b-button>
              <b-link class="btn btn-warning" to="/patient">Annuler</b-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import axios from "axios";
import ApiService from "../../Utils/axios";

export default {
  data() {
    return {
      data: null,

      form: {
        firstname: null,
        lastname: null,
        patient_code: null,
        dateofbirth: null,
        gender: null,
        difficult_intubation: null,
        type: null,
      },
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      const { data } = await new ApiService().get(
        "/api/patient/" + this.$route.params.id
      );

      this.data = data;
    },
    async submit(e) {
      //   const formData = new FormData(e.target);
      this.form.firstname = this.data.firstname;
      this.form.lastname = this.data.code;
      this.form.patient_code = this.data.patient_code;
      this.form.dateofbirth = this.data.dateofbirth;
      this.form.gender = this.data.gender;
      this.form.difficult_intubation = this.data.difficult_intubation;
      this.form.type = this.data.type;
      await new ApiService()
        .put("/api/patient/" + this.$route.params.id, this.form)
        .then((res) => {
          console.log("res", res);
          //   this.$router.push("/patient");
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
  },
};
</script>

<style></style>
