<script>
import ApiService from "../../Utils/axios.js";

export default {
  data() {
    return {
      surgeries: [],
      done: false,
      cols: [
        {
          label: "Id",
          key: "id",
          sortable: true,
        },
        {
          label: "Dénomination",
          key: "denomination",
          sortable: true,
        },
        {
          label: "Code",
          key: "code",
        },
        {
          label: "Description",
          key: "summary",
        },

        {
          label: "Synonymes",
          key: "synonyms",
          sortable: true,
        },
        {
          lable: "Modifier",
          key: "edit",
        },
      ],
    };
  },

  beforeMount() {
    this.fetchList();
  },

  methods: {
    fetchList() {
      this.done = false;
      // axios
      new ApiService()
        .get("/api/surgery")
        .then((res) => {
          this.surgeries = res.data;
          this.done = true;
          console.log("surgeries", this.surgeries);
          console.log("res", res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<template>
  <div>
    <b-container class="mt-5">
      <b-row>
        <b-col-3 class="mb-3">
          <router-link to="/surgery/new" class="btn btn-success"
            >Ajouter
          </router-link>
        </b-col-3>
      </b-row>
      <b-row>
        <b-col>
          <b-table striped hover :items="surgeries" :fields="cols">
            <template v-slot:cell(edit)="data"
              ><router-link :to="'/surgery/' + data.item.id"
                ><i class="fas fa-edit"></i></router-link
            ></template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
