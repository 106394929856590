<script>
import ApiService from "../../Utils/axios.js";

export default {
    data() {
        return {
            journeys: [],
            done: false,
            cols: [
                {
                    label: "Id",
                    key: "id",
                    sortable: true,
                },
                {
                    label: "Ref",
                    key: "reference",
                    sortable: true,
                },
                {
                    label: "Raison",
                    key: "reason",
                },
                {
                    label: "Prénom",
                    key: "firstname",
                },

                {
                    label: "Nom",
                    key: "lastname",
                    sortable: true,
                },
                {
                    label: "Intervention",
                    key: "surgery",
                    sortable: true,
                },
                {
                    lable: "Modifier",
                    key: "edit",
                },
            ],
        };
    },

    beforeMount() {
        this.fetchList();
    },

    methods: {
        fetchList() {
            this.done = false;
            // axios
            new ApiService()
                .get("/api/journey")
                .then((res) => {
                    res.data.data.forEach((e) => {
                        this.journeys.push({
                            id: e.id,
                            reference: e.reference,
                            reason: e.reason,
                            firstname: e.patient.firstname,
                            lastname: e.patient.lastname,
                            surgery: e.surgery.denomination,
                            // CheckListes: [e.forEach((cl) => {})],
                        });
                    });
                    // this.journeys = res.data.data;
                    this.done = true;
                    console.log("journeys", this.journeys);
                    console.log("res", res);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>
<template>
    <div>
        <b-container class="mt-5">
            <b-row>
                <b-col-3 class="mb-3">
                    <router-link to="/journey/new" class="btn btn-success"
                        >Ajouter
                    </router-link>
                </b-col-3>
            </b-row>
            <b-row>
                <b-col>
                    <b-table striped hover :items="journeys" :fields="cols">
                        <template v-slot:cell(edit)="data"
                            ><router-link :to="'/journey/edit/' + data.item.id"
                                ><i class="fas fa-edit"></i></router-link
                        ></template>
                    </b-table>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
