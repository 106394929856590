<template>
  <b-container class="mt-5">
    <div class="card">
      <div class="card-header d-flex">
        <div class="header-title">
          <h5 class="card-title">Modifier un utilisateur</h5>
        </div>
      </div>
      <div v-if="user" class="card-body d-flex flex-column">
        <form @submit.prevent="submit">
          <div class="row">
            <div class="form-group col-12 col-lg-6">
              <label>Email</label>
              <input
                type="text"
                placeholder="Email"
                class="form-control mb-2"
                name="email"
                v-model="user.email"
              />
            </div>

            <div class="form-group col-12 col-lg-6">
              <label>Nom</label>
              <input
                type="text"
                placeholder="Nom"
                class="form-control mb-2"
                name="name"
                v-model="user.name"
              />
            </div>

            <div class="form-group col-12 col-lg-6">
              <label>Mot de passe</label>

              <b-input-group>
                <b-input-group-prepend is-text>
                  <b-form-checkbox v-model="show" switch class="mr-n2">
                  </b-form-checkbox>
                </b-input-group-prepend>
                <b-form-input
                  :type="show ? 'text' : 'password'"
                  placeholder="Mot de passe"
                  name="password"
                  v-model="user.password"
                ></b-form-input>
              </b-input-group>
            </div>
          </div>

          <div class="mt-3">
            <b-button type="submit" class="btn btn-success m-2"
              >Valider</b-button
            >
            <b-link class="btn btn-warning" to="/user">Annuler</b-link>
          </div>
        </form>
      </div>
    </div></b-container
  >
</template>

<script>
import ApiService from "../../Utils/axios";

export default {
  data: () => ({
    show: false,
    user: undefined,
  }),
  mounted() {
    this.getUser();
  },
  methods: {
    async submit(e) {
      try {
        const formData = new FormData(e.target);

        await new ApiService().put(
          "/api/user/" + this.$route.params.id,
          formData
        );

        this.$router.push("/user");
      } catch (e) {
        console.log(e);
      }
    },
    async getUser() {
      try {
        const { data } = await new ApiService().get(
          "/api/user/" + this.$route.params.id
        );

        console.log(data);
        this.user = data;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style></style>
